export const DEFAULT_VALUES = {
  question1: '',
  question2: '',
  question3: '',
  question4: '',
  question5: '',
  grade: '1',
  majors: '전기공학과',
  agree1: false,
  agree2: false,
  agree3: false,
  link: '',
};
